import { gql } from 'nuxt-graphql-request'
import {
  Asset,
  TeamMemberCard,
  BlogPostCard,
  Testimonial,
  PageHero,
  EventsPreview,
  SwisstainableDetailpagePreview,
} from './fragments'

// Get Team Members
export const getTeamMembers = gql`
  query getTeamMembers($isChairman: Boolean = false, $hidden: Boolean = false, $lang: String) {
    entries(
      collection: "team"
      sort: "order"
      filter: { published: { is: true }, is_chairman: { is: $isChairman }, hidden: { is: $hidden }, locale: { is: $lang } }
    ) {
      data {
        ${TeamMemberCard}
      }
    }
  }
  ${Asset}
`

// Get Blog Posts
export const getBlogPosts = gql`
  query getBlogPosts($page: Int = 1, $limit: Int, $filter: JsonArgument) {
    entries(
      collection: "blog_posts"
      filter: $filter
      limit: $limit
      page: $page
      sort: "date desc"
    ) {
      total
      current_page
      from
      to
      has_more_pages
      last_page
      data {
        ...BlogPostCard
      }
    }
  }
  ${Asset}
  ${BlogPostCard}
`

export const getSwisstainableIllustrationItems = gql`
  query getSwisstainableIllustrationItems($lang: String) {
    collection(handle: "swisstainable_illustration_items") {
      structure {
        max_depth
        tree(site: $lang) {
          page {
            ... on Entry_SwisstainableIllustrationItems_SwisstainableIllustrationMilestone {
              id
              title
              popup_title
              text
              link_text
              link
              blueprint
            }
          }
          children {
            page {
              ... on Entry_SwisstainableIllustrationItems_SwisstainableIllustrationStep {
                id
                title
                popup_title
                text
                link_text
                link
                blueprint
              }
              ... on Entry_SwisstainableIllustrationItems_SwisstainableIllustrationDeepDive {
                id
                title
                color
                popup_title
                text
                link_text
                link
                blueprint
              }
            }
          }
        }
      }
    }
  }
`

// Get Blog Posts by tag
export const getBlogPostsByTag = gql`
  query getBlogPostsByTag($page: Int = 1, $limit: Int, $filter: JsonArgument) {
    entries(
      collection: "blog_posts"
      filter: $filter
      limit: $limit
      page: $page
      sort: "date desc"
    ) {
      total
      current_page
      from
      to
      has_more_pages
      last_page
      data {
        ...BlogPostCard
      }
    }
  }
  ${Asset}
  ${BlogPostCard}
`

// Get Swisstainable Detailpages
export const getSwisstainableDetailpages = gql`
  query getSwisstainableDetailpages(
    $page: Int = 1
    $limit: Int
    $filter: JsonArgument
  ) {
    entries(
      collection: "swisstainable_hub"
      filter: $filter
      limit: $limit
      page: $page
      sort: "date desc"
    ) {
      total
      current_page
      from
      to
      has_more_pages
      last_page
      data {
        ...SwisstainableDetailpagePreview
      }
    }
  }
  ${Asset}
  ${SwisstainableDetailpagePreview}
`

// Get Consultants
export const getConsultants = gql`
  query getConsultants($lang: String, $limit: Int) {
    entries(
      collection: "consultants"
      filter: { published: { is: true }, locale: { is: $lang } }
      limit: $limit
      sort: "order"
    ) {
      data {
        ... on Entry_Consultants_Consultants {
          slug
          title
          text
          location
          link
          image {
            ...Asset
          }
          image_layout {
            value
          }
          consultant_categories {
            title
            slug
          }
        }
      }
    }
  }
  ${Asset}
`

// Get Member Offers
export const getMemberOffers = gql`
  query getMemberOffers($lang: String, $limit: Int) {
    entries(
      collection: "mitgliederangebote"
      filter: { published: { is: true }, locale: { is: $lang } }
      limit: $limit
      sort: "order"
    ) {
      data {
        ... on Entry_Mitgliederangebote_Mitgliederangebote {
          id
          title
          text
          link
          link_to_form
          slug
          image {
            ...Asset
          }
          cover_image {
            ...Asset
          }
        }
      }
    }
  }
  ${Asset}
`

// Get SUMA Offers
export const getSumaOffers = gql`
  query getSumaOffers($lang: String, $excludeSlug: String) {
    entries(
      collection: "offers"
      filter: {
        published: { is: true }
        locale: { is: $lang }
        slug: { not: $excludeSlug }
      }
      sort: "title desc"
    ) {
      data {
        ... on Entry_Offers_Offer {
          blueprint
          slug
          uri
          title
          intro
          link_text
          link
          country {
            label
            value
          }
          offers_categories {
            ... on Term_OffersCategories_OffersCategory {
              slug
              title
            }
          }
          cover_image {
            ...Asset
          }
          logo {
            ...Asset
          }
        }
      }
    }
  }

  ${Asset}
`

// Get Products
export const getProducts = gql`
  query getProducts($lang: String, $limit: Int) {
    entries(
      collection: "products"
      filter: { published: { is: true }, locale: { is: $lang } }
      limit: $limit
      sort: "order"
    ) {
      data {
        ... on Entry_Products_Products {
          id
          title
          text
          can_be_ordered
          image {
            ...Asset
          }
          downloads {
            language {
              value
              label
            }
            file {
              ...Asset
            }
          }
        }
      }
    }
  }
  ${Asset}
`

// Get Testimonials
export const getTestimonials = gql`
  query getTestimonials($lang: String, $limit: Int) {
    entriesRandom(
      collection: "testimonials"
      filter: { site: $lang }
      limit: $limit
      sort: "random"
    ) {
      data {
        ...Testimonial
      }
    }
  }
  ${Asset}
  ${Testimonial}
`

// Get Events
export const getEventsPreview = gql`
  query getEvents(
    $limit: Int
    $page: Int
    $filter: JsonArgument
    $sort: String
  ) {
    entries(
      collection: "events"
      limit: $limit
      page: $page
      filter: $filter
      sort: [$sort]
    ) {
      current_page
      has_more_pages
      data {
        ...EventsPreview
      }
    }
  }
  ${EventsPreview}
`

// Get getPagesPreview
export const getPagesPreview = gql`
  query getPagesPreview($filter: JsonArgument) {
    entries(filter: $filter) {
      data {
        slug
        url
        ...PageHero
        ... on Entry_Pages_Page {
          page_categories {
            slug
            title
          }
        }
      }
    }
  }
  ${Asset}
  ${PageHero}
`
